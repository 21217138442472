import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../../static/kuragegurashi_logo.svg'
import { AppBar, Grid, Toolbar } from '@material-ui/core'
import RssFeedIcon from '@material-ui/icons/RssFeed'

export default () => (
  <header style={{ backgroundColor: '#3894D2' }}>
    <AppBar position="relative" style={{ backgroundColor: '#3894D2', boxShadow: 'none' }}>
      <Toolbar>
        <Grid container justify='center'>
          <Grid item>
            <Link to='/'>
              <img src={Logo} alt='くらげぐらしのロゴ' style={{ margin: '10px 0px 10px 0px' }}/>
            </Link>
          </Grid>
        </Grid>
        <Link to='/rss.xml'>
          <RssFeedIcon fontSize="large" />
        </Link>
      </Toolbar>
    </AppBar>
  </header>
)
