import React from 'react'
import { Grid, Link } from '@material-ui/core';

/** 現在日時。 */
const now = new Date();
/** 現在日時の年号。 */
const year = now.getFullYear();

export default () => (
  <footer style={{ backgroundColor: '#3894D2', color: '#ffffff', padding: 10 }}>
    <Grid container justify='center'>
      <Grid item>
        &copy; {year} minamo173
      </Grid>
    </Grid>
  </footer>
)
